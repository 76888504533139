@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d1d1d !important;
}

.lockr-bg-img {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 100% top;
  background-image: url(https://publisher.lockrmail.com/publisher/images/onboard_bkgnd_web.a9c720ff.svg);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
}

input {
  background-color: none !important;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  padding: 24px 0 8px 16px;
}

.signup-option {
  margin-top: 80px;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: white;
  line-height: 30px !important;
}

.signin-option {
  font-weight: 700;
  color: #61cef7;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 8px;
}

.lockr-website-control input[type="text"] {
  border-radius: 0px !important;
}

@media screen and (min-width: 767px) {
  .lockr-signup-control input {
    width: 445px !important;
  }

  .login-button {
    width: 263px !important;
  }

  .password-info {
    width: 445px !important;
  }

  .lockr-form-control {
    width: 443px;
  }

  .reg-container {
    padding-left: 91px;
    margin-top: 232px;
    padding-bottom: 90px;
  }
}

.lockr-bg-img input:focus {
  outline: none !important;
  border-color: #61CEF7 !important;
  box-shadow: 0 0 0px #719ECE;
}

.lockr-form-control {
  border: 1px solid #8B8B8B;
  box-sizing: border-box;
  border-radius: 0px;
}

.login-control .lockr-form-control:first-child {
  /* border-bottom: 0px !important; */
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.login-control .lockr-form-control:last-child {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.lockr-join2 .ant-col:first-child .ant-input {
  border-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.lockr-join2 input {
  height: 55.5px !important;
}

.lockr-join2 .ant-col:last-child .ant-input {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.lockr-join2plus .ant-col:first-child .ant-input {
  border-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.lockr-join2plus .ant-col:last-child .ant-input {
  border-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.lockr-join2plus input {
  height: 55.5px !important;
}


.lockr-form-control-focus {
  outline: none !important;
  border-color: #61CEF7 !important;
  box-shadow: 0 0 0px #719ECE;
}

.lockr-logo {
  width: 34px;
  height: 54px;
}

.lockr-link {
  color: #2e70d2 !important;
}

.page-header-options {
  font-weight: 400px;
  font-size: 14px;
  font-family: 'Montserrat';
}

.new-app-modal-input {
  padding-top: 8px !important;
}

/* input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: textfield;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  -moz-appearance: none;
} */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* .login-back {
  background: #1d1d1d;
  background-image: url(./assets/onboard_bkgnd_web.a9c720ff.png);
  position: absolute;
  width: 100%;
  /* background-size: cover; */
/* background-repeat: no-repeat; */
/* } */

.ant-empty-normal {
  color: #fff !important;
}

.ant-menu-item-selected {
  background-color: #00b5e2 !important;
  color: #fff
}

.ant-menu-item {
  font-family: 'Montserrat';
}

h1 {
  color: white !important;
  font-family: 'Montserrat';
}



.login-button {
  border: none !important;
  color: #afb4b9 !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  font-weight: bold !important;
  height: 60px !important;
}

.ant-btn-primary {
  background-color: #ffffff !important;
  border: none !important;
  color: black !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  font-weight: bold !important;
  height: 60px;
}

.ant-btn-primary:hover {
  background-color: #61cef7 !important;
  border: none !important;
  color: black !important;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: bold;
  /* height: 60px ;  */
  font-weight: bold !important;
}

.snowflake-frequency-picker-container>.ant-dropdown-trigger {
  width: 366px;
}

.connection-tile-font {
  font-size: 14px !important;
}

.new-app-modal-btn {
  height: 56px !important;
  width: 172px;
  color: #26282A !important;
  background-color: #61CEF7 !important;
}

.btn-first-connection {
  height: 56px !important;
  color: #26282A !important;
  background-color: #61CEF7 !important;
}

.new-app-modal-btn>span {
  color: #26282A !important;
}


.ant-menu-vertical {
  border-right: 1px solid transparent !important;
}

.ant-menu-item {
  font-size: 16px !important;
  margin-left: 12px !important;
}

.ant-typography {
  color: white !important;
  font-family: 'Montserrat';
  font-size: 16px;
}

.ant-typography.sub-text {
  color: rgb(186, 184, 184) !important
}


.ant-typography.licenseAgreement>.ant-typography {
  font-size: 12px !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 14.63px;
}

.verificationModal .ant-modal-header {
  background-color: #1d1d1d;
  font-family: 'Montserrat';
  padding-left: 60px;
  border-bottom: 0;
  padding-right: 60px;
  padding-top: 48px;
  padding-bottom: 24px;
  border-radius: 0px;
}

.appCreatedModal .ant-modal-content {
  width: 776px;
}

.appCreatedModal .ant-modal-body {
  padding-top: 48px !important;
}

.appCreatedModal .ant-modal-header {
  background-color: #1d1d1d;
  font-family: 'Montserrat';
  padding: 10px 24px;
  border-bottom: 0
}

.appCreatedModal .ant-modal-footer {
  background-color: #1d1d1d;
  font-family: 'Montserrat';
  padding: 10px 24px;
  border-top: 0
}

a.ant-typography {
  color: #61CEF7 !important;
}

.sign-in[disabled],
.login-button[disabled] {
  background: #000 !important;
  color: #8b8b8b !important;
}

.sign-in,
.login-button {
  border: none !important;
  color: #26282a !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  font-weight: bold !important;
  height: 56px !important;
  background: #61cef7 !important;
}
.connection-button{
  border: none !important;
  color: #26282a !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  font-weight: bold !important;
  /* height: 56px !important; */
  background: #61cef7 !important;
  word-wrap: break-word !important;
  padding: 8px 12px !important;
  height: auto !important;
}

.cancel-button:hover {
  border: 1px solid #61cef7 !important;
  color: #61cef7 !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  font-weight: bold !important;
  height: 56px !important;
  background: transparent !important;
}

.cancel-button {
  border: 1px solid #61cef7 !important;
  color: #61cef7 !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  font-weight: bold !important;
  height: 56px !important;
  background: transparent !important;
}

.verify-button {
  width: 263px !important;
  border: 1px solid #00b5e2 !important;
  color: #26282a !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  font-weight: bold !important;
  height: 56px !important;
  background: transparent !important;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.loading-img {
  animation: rotate 1.4s linear infinite;
  -webkit-animation: rotate 1.4s linear infinite;
  -moz-animation: rotate 1.4s linear infinite;
}

.verify-button span {
  color: #00b5e2;
}

.ant-input {
  background-color: #fffff000 !important;
  font-family: 'Montserrat';
  font-size: 16px !important;
  width: 60vw !important;
  height: 56px;
}

.ant-input-40 {
  height: 40px !important;
}

label {
  font-size: 14px !important;
}

.ant-menu-item-selected {
  background-color: #61cef7 !important;
  font-family: 'Montserrat';
  font-size: 16px;
}

.reg-common-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 52px;
  color: #ffffff;
}

th {
  background-color: #171717 !important;
  color: #8B8B8B !important;
  font-family: 'Montserrat';
  font-size: 16px;
}

.duplicate-connection-subtitle {
  color: #8B8B8B !important
}

.ant-table-cell {
  background-color: #171717 !important;
  color: white;
  border-bottom: 1px solid #26282a !important;
  font-family: 'Montserrat';
  font-size: 16px;
}

.connection-list-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead tr th {
  background-color: black !important;
  border-bottom: 0px solid transparent !important;
}

.connection-list-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody tr td {
  background-color: black !important;
}

.connection-list-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody tr:last-child td {
  border-bottom: 0px solid transparent !important;
}

.add-setting-container {
  margin-top: 32px;
  padding-left: 16px;
  margin-left: 16px;
  padding-right: 0px;
  margin-right: 16px;
  padding-bottom: 32px;
  padding-top: 32px;
  border: 1px solid #ffffff22;
}

.add-setting-title {
  font-size: 22px !important;
  font-family: 'Montserrat' !important;
  margin-bottom: 28px !important;
}

.reg-steps.ant-progress {
  line-height: 0;
}

.reg-steps .ant-progress-inner {
  background-color: #1d1d1d00;
  border-radius: 0px;
}

.reg-steps .ant-progress-bg,
.reg-steps .ant-progress-status-success .ant-progress-bg {
  background-color: #2E70D2 !important;
  border-radius: 0px;
}

.licenseCard {
  width: 620px;
  min-height: 240px;
  max-height: 240px;
  overflow: hidden;
  padding: 20px 20px 0px 20px;
  /* overflow-x: hidden; */
}

.createAppButton {
  text-align: right;
}

.addApplication-cancle {
  margin-left: 15px;
}

.errorInputText {
  color: #f46666 !important;
}

.errorText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  margin-top: 4px;
  color: #f46666
}

.errorText-register {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  max-width: 680px;
  line-height: 32px;
  margin-top: 4px;
  color: #f46666
}

.agreement-checkbox .ant-checkbox-inner {
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
.licenseCard::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.licenseCard::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
.licenseCard::-webkit-scrollbar-thumb {
  background: #1d1d1d;
  border-radius: 10px;
}

.licenseAgreement .lastUpdate {
  color: #8b8b8b !important;
}

.licenseTextPrompter {
  overflow: scroll;
  max-height: 210px;
  min-height: 210px;
  overflow-x: hidden
}

@media (max-width: 700px) {
  .reg-container {
    padding-left: 32px;
    margin-top: 126px;
    padding-bottom: 20px;
  }

  .reg-container .ant-input {
    width: 90% !important;
  }

  .licenseCard {
    width: 311px;
    min-height: 210px;
  }

  .verificationModal.ant-modal,
  .verificationModal .ant-modal-content {
    height: 100vh !important;
    width: 100vw !important;
    margin: 0;
    top: 0;
    background-color: #1d1d1d;
    border-radius: 0px !important;
  }

  .verificationModal .ant-modal-body {
    height: calc(98vh - 110px);
  }

  .createAppButton {
    text-align: left;
  }

  .add-setting-container .ant-input {
    width: 90% !important;
  }

  .addApplication-cancle {
    margin-top: 15px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 767.99px) {
  .ant-layout {
    position: relative;
    overflow: hidden;
    min-width: 100vw;
  }
}

@media only screen and (max-width: 775px) {
  /* .snowflake-header{
    flex-wrap: wrap;
  } */
}
.snoflake-sidebar {
  min-width: 294px;
  max-width: 294px;
}

/* Media query for screens below 1400px */
@media (max-width: 1499px) {
  .snoflake-sidebar {
    min-width: 150px;
    max-width: 150px;
  }
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8b8b8b;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8b8b8b;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8b8b8b;
}

.edit-btn {
  height: 32px !important;
  width: 32px !important;
  border-color: '#888888' !important;
  /* border-radius: 5px !important; */
  margin-right: 16px;
  color: "#fff" !important;
  background-color: "transparent" !important;
  /* padding: 8px !important; */
}

.edit-btn:hover {
  cursor: pointer;
}

.delete-btn {
  height: 32px !important;
  width: 32px !important;
  border-color: '#888888' !important;
  /* border-radius: 5px !important; */
  /* margin-right: 20px; */
  color: "#fff" !important;
  background-color: "transparent" !important;
  /* padding: 8px !important; */
}

.delete-btn:hover {
  cursor: pointer;
}

.site-page-header {
  background-color: #171717 !important;
  border-bottom-width: 1px !important;
  padding-left: 91px !important;
  padding-right: 91px !important;
  border-bottom: 1px solid #353738;
  height: 91px;
  padding-top: 23px !important;
}

.apiandservices-newapp-btn {
  margin-top: 60px;
  margin-right: 95px;
  margin-bottom: 60px;
  margin-left: 95px;
}

.identity-suite-dashboard-heading {
  margin-top: 60px;
  margin-right: 91px;
  margin-bottom: 60px;
  margin-left: 91px;
}

.datepicker-btw-to {
  font-family: 'Montserrat';
  font-style: normal;
  color: #fff !important;
  font-weight: 400;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
}

.datepicker-btw-to-view-options {
  font-family: 'Montserrat';
  font-style: normal;
  color: #fff !important;
  font-weight: 400;
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
}

.dashboard-date-picker {
  background-color: #1D1D1D !important;
  border: 1px solid #26282A !important;
  height: 40px;
  width: 173px;
  cursor: pointer;
}

.dashboard-date-picker-event-log,
.dashboard-date-picker-event-log .ant-picker-input input {
  cursor: pointer;
}

.dashboard-date-picker-event-log {
  font-family: 'Montserrat';
  background-color: #1D1D1D !important;
  border: 1px solid #26282A !important;
  height: 40px;
  width: 160px;
  z-index: 1200;
}

.dashboard-date-picker-event-log>.ant-picker-input>input {
  font-size: 13px;
}

.dashboard-date-picker .ant-picker-input>input {
  color: #fff;
}

.dashboard-date-picker-event-log .ant-picker-input>input {
  color: #fff;
}



.calender-date-picker .ant-picker-panel,
.calender-date-picker .ant-picker-date-panel,
.calender-date-picker .ant-picker-header-view,
.calender-date-picker .ant-picker-cell-inner,
.calender-date-picker .ant-picker-footer,
.calender-date-picker .ant-picker-today-btn,
.calender-date-picker .ant-picker-year-panel,
.calender-date-picker .ant-picker-month-panel,
.calender-date-picker .ant-picker-decade-panel,
.calender-date-picker th {
  background-color: #000 !important;
  color: #fff !important;
  font-family: 'Montserrat';
  font-size: 12px;
}


.calender-date-picker th {
  color: #8B8B8B !important;
  font-size: 10px;
  width: 50px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  display: none !important;
}

.calender-date-picker .ant-picker-header {
  border: 0;
  width: 375px;
}

.calender-date-picker .ant-picker-content {
  width: 330px;
}

.calender-date-picker .ant-picker-panel {
  border: 0px;
  width: 375px;
}

.calender-date-picker .ant-picker-footer {
  display: none;
}

.calender-date-picker .ant-picker-header button {
  color: white;
  font-size: 14px;
}

.calender-date-picker .ant-picker-cell-selected .ant-picker-cell-inner {
  color: #61CEF7 !important
}

.calender-date-picker .ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 0px !important;
}


.calender-date-picker .ant-picker-cell-disabled .ant-picker-cell-inner {
  color: #8B8B8B !important;

}

.ant-picker-panel-container .ant-picker-panel {
  width: 375px;
}

.ant-picker-decade-panel button {
  color: white !important
}

.dashboard-as-of-today {
  font-size: 14px !important;
}

.dashboard-as-of-today .ant-typography {
  color: #8B8B8B !important;
  font-size: 14px !important;
}

.dashboard-analytics-counts-board-row {
  /* margin-left: 91px;
  margin-right: 91px; */
  padding: 0px 81px;
}

.dashboard-analytics-counts-board-cols {
  background-color: black;
  height: 120px;
}

.dashboard-analytics-counts-board-cols-left {
  background-color: black;
  height: 120px;
}

.dashboard-analytics-counts-board-cols-right {
  background-color: black;
  height: 120px;
}

.ant-col-lg-5 {
  display: block;
  flex: 0 0 20% !important;
  max-width: 20% !important;
  padding: 0 10px;
}

.dashboard-analytics-counts {
  color: #2E70D2 !important;
  font-size: 44px !important;
  padding-left: 24px;
  padding-top: 16px;
  font-family: "Montserrat";
  align-items: baseline;
}

.dashboard-analytics-counts-change {
  color: #8B8B8B !important;
  font-size: 14px !important;
  /* margin-top: 28px; */
  margin-left: 6px;
  margin-bottom: 0px !important;

}

.dashboard-analytics-counts-change-text {
  font-size: 14px !important;
}


.dashboard-analytics-counts-change-up {
  color: #50E8B1 !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  /* margin-top: 28px; */
  margin-left: 6px;
}

.dashboard-analytics-counts-change-down {
  color: #F46666 !important;
  font-size: 14px !important;
  /* margin-top: 28px; */
  margin-left: 6px;
  margin-bottom: 0px !important;
}

.dashboard-card-container {
  flex: 1 !important;
  max-width: 256px !important;
}

.dashboard-title {
  font-weight: 700 !important;
  margin-bottom: 8px !important;
  font-size: 24px !important;
  font-family: "Montserrat" !important;
}

.dashboard-actual-counts {
  color: #2E70D2 !important;
  font-size: 44px !important;
  margin-bottom: 0px !important;
}

.dashboard-analytics-box-titles {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  margin-left: 24px;
  margin-top: 4px;
}

.dashboard-table-heading {
  font-family: 'Montserrat';
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  padding-left: 24px;
  padding-Top: 20px;
}

.dashboard-tables .ant-table-cell {
  background-color: #000000 !important;
  padding-left: 0px;
}

.analytics-tables-cell .ant-table-cell {
  padding-left: 0px;
}

.more-connection-description-text {
  color: #8B8B8B !important;
}

.font-twelve {
  font-size: 12px !important;
}

.dashboard-tables th {
  font-size: 12px !important;
  color: #8B8B8B;
}

.dashboard-download-btn {
  width: auto;
  background: transparent !important;
  border: 1px solid #61CEF7 !important;
  color: #61CEF7 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: 20px;
  text-align: end;
}

.dashboard-download-btn:hover {
  background: transparent !important;
  border: 1px solid #61CEF7 !important;
  color: #61CEF7 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.download-icon {
  height: 11px;
  width: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.dashboard-tables td {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

.apiandservices-heading {
  font-size: 24px !important;
  font-family: "Montserrat";
  /* padding-left: 91px; */
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  font-weight: 700 !important;
}

.support-heading {
  font-size: 44px !important;
  font-family: "Montserrat";
  /* padding-left: 91px; */
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  font-weight: 700 !important;
}

.apiandservices-sub-heading {
  font-family: "Montserrat";
  /* padding-left: 91px !important; */
  color: #8B8B8B !important;
  padding-top: 0px !important;
  margin-top: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.lockr-logo-pagehead {
  width: 80px;
  height: 30px;
}

.app-table-header {
  color: #8B8B8B;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

}

.dashboard-tables .ant-pagination-item-link,
.dashboard-tables .ant-table-pagination {

  background-color: #000 !important;
  color: #8B8B8B !important;
  border: 0px !important;
  font-size: 12px !important;
}

.dashboard-tables .ant-pagination-item {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  margin: 0px !important;
  min-width: 26px !important;
}

.ant-pagination-prev {
  margin-right: 0px !important;
}

.dashboard-tables .ant-pagination-item a {
  padding: 0px;
}

.dashboard-tables .ant-table-pagination>* {
  background-color: #000 !important;
  border: 0px
}

.dashboard-tables .ant-pagination-item a {
  color: #8B8B8B !important
}

.dashboard-tables .ant-pagination-item-active a {
  color: #61CEF7 !important
}

.footer-style {
  background: #171717 !important;
  border-top: 1px solid #353738 !important;
  height: 94px;
  margin-top: 50px;
  padding-left: 91px !important;
  padding-right: 91px !important;
}

.ant-layout-footer {
  padding-top: 32px !important;
}

.footer-logo {
  height: 19px;
  width: 30px;
}


.filter-chip-parent {
	padding: 8px;
	border: 1px solid #8B8B8B;
	background-color: #353738;
	border-radius: 100px;
	height: 31px;
	display: flex;
	justify-content: center;
	align-items: center;
  max-width: 200px;

	&-linear-border{
	  border-radius: 100px;
    padding: 8px;
    background-color: #1C2D27;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dashboard {
      max-width: 300px;
    }
	}
  &.-disabled {
    border-color: #5c5b5b
  }
}


.filter-chip-text {
	@include montserrat(normal, normal, 12px);
	color: #FFFFFF;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  &.-disabled {
    color: #afafaf
  }
}

.dashboard-tables .ant-table-tbody>tr>td {
  overflow-wrap: anywhere !important;
}

.view-options-dropdown {
  background-color: #353738;
  width: 395px;
  padding-top: 20px;
  padding-left: 24px;
  min-height: 389px;
  padding-bottom: 20px;
}

.identity-provider-options-dropdown {
  background-color: #353738;
  width: 200px;
  padding-top: 10px;
  padding-left: 24px;
  min-height: 100px;
  padding-bottom: 20px;
  max-height: 300px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.view-option-divider {
  border: 1px solid #1D1D1D;
  width: 385px;
  height: 0px;
  margin-left: -24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashboard-view-more-options {
  color: #fff !important;
  height: 29px !important;
  width: auto !important;
  background: transparent !important;
  border: 1px solid #26282A !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.title-text-disabled-style {
  background-color: #595959 !important;
  opacity: 0.5;
}

.custom-view-more-options {
  color: #fff !important;
  height: 41px !important;
  width: 200px !important;
  background: transparent !important;
  border: 1px solid #26282A !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  margin-right: 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: start !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  &.small-view-more-options {
    height: 29px !important;
  }
}

.custom-view-more-options:hover {
  color: #fff !important;
  height: 41px !important;
  width: 200px !important;
  background: transparent !important;
  border: 1px solid #26282A !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  margin-right: 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: start !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  &.small-view-more-options {
    height: 29px !important;
  }
}


.dashboard-view-more-options:hover {
  color: #fff !important;
  height: 29px !important;
  width: auto !important;
  background: transparent !important;
  border: 1px solid #26282A !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.avatar-icon {
  height: 14px;
  width: 36px;
}

.avatar-icon:hover {
  cursor: pointer;
}

.ant-modal-body {
  overflow-x: hidden !important
}

.add-uri-btn {
  background: transparent !important;
  width: 119 !important;
  height: 40 !important;
  color: #61CEF7 !important;
  border: 1px solid #61CEF7 !important;
  font-family: 'Montserrat';
}

.modal-delete-icon {
  height: 40px;
  width: 40px;
}

.modal-delete-icon:hover {
  cursor: pointer;
}

.active-view-option {
  margin-left: 12px;
  color: #61CEF7 !important;
}

.active-view-option:hover {
  color: #61CEF7 !important;
  cursor: pointer;
}

.inactive-view-option {
  margin-left: 12px;
}

.inactive-view-option:hover {

  color: #61CEF7 !important;
  cursor: pointer !important;
}

.ant-empty-image {
  display: none;
}

.ant-empty-description {
  display: none;
}

.ant-empty {
  height: 150px;
}

.ant-pagination-options {
  display: none !important;
}

.ant-picker-cell-inner:hover,
.ant-picker-cell:hover,
.ant-picker-cell-in-view:hover {
  background: #000 !important;
}

.analytics-heading {
  margin-top: 60px;
  margin-right: 91px;
  margin-bottom: 60px;
  margin-left: 91px;
}

.pie-chart-title {
  font-family: 'Montserrat';
  font-weight: 700 !important;
  font-size: 20px !important;
}

.legend-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

}

.analytics-tables {
  margin-top: 80px;
  margin-right: 91px;
  margin-bottom: 60px;
  margin-left: 91px;
}

.analytics-table-head {
  color: #fff !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.new-app-modal-x-button {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-left: 445px;
  margin-bottom: 74px;
  cursor: pointer !important;

}

.account-menu-typography {
  font-size: 14px;
  color: #8B8B8B !important;
}

.new-app-modal-x-button-small {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-left: 445px;
  margin-bottom: 74px;
  cursor: pointer !important;

}

.profile-dropdown-menu {
  background-color: #000 !important;
  color: #fff !important;
  width: 152px !important;
  margin-top: 17px !important;
  margin-right: 48px !important;
}

.dashboard-date-picker>.ant-picker-input>input {
  cursor: pointer;
}

.profile-dropdown-text label {
  font-size: 14px !important;
  cursor: pointer;
}




.verticle-dropdown {
  width: 445px;

}

.contact-form-dropdown {
  width: 445px;
  height: 50px !important;
}

.verticle-dropdown-container {
  margin-top: -5px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  width: 600px;
  max-height: 200px;
  overflow: scroll;
}

.verticle-dropdown-option {
  width: 415px;
  font-size: 14px;
  font-family: 'Montserrat';
  padding: 6px 6px 6px 6px;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.frequency-dropdown-option {
  width: 215px;
  font-size: 14px;
  font-family: 'Montserrat';
  padding: 6px 6px 6px 6px;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.verticle-dropdown-option:hover {
  background: #1d1d1d !important;
  cursor: pointer !important;
}

.verticle-dropdown input {
  cursor: pointer !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #26282a;
  border-radius: 10px;
}

.delete-app-modal-input {
  width: 350px !important;
  margin-top: 32px !important;
  height: 40px !important;
}

.delete-app-modal-forinput input {
  padding: 0px !important;
  padding-left: 8px !important;
  color: white !important
}

.connection-action-tooltip-subtitle {
  font-size: 12px !important;
  font-weight: 400;
  font-style: normal;
  font-family: 'Montserrat';
  color: #8B8B8B !important;
  margin-top: 4px;
}

.ant-popover-arrow {
  display: none !important;
}

.connection-action-tooltip-title {
  font-size: 12px !important;
  font-weight: 400;
  font-style: normal;
  font-family: 'Montserrat';
  color: #FFFFFF !important;
}

.delete-modal-x-button {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-left: 208px;
  margin-bottom: 74px;
  cursor: pointer !important;
}

.delete-x-button-connection {
  height: 20px;
  width: 20px;
  right: 40px;
  display: inline-block;
  position: absolute;
  cursor: pointer !important;
}

.lockr-api-docs-cross-x-button {
  height: 20px;
  width: 20px;
  right: 50px;
  display: inline-block;
  position: absolute;
  cursor: pointer !important;
}

.delete-x-button-review {
  height: 20px;
  width: 20px;
  right: 40px;
  display: inline-block;
  position: absolute;
  cursor: pointer !important;
}

.ant-table-container {
  background: black !important;
}

.app-create-modal-x-button {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-left: 225px;
  margin-bottom: 74px;
  cursor: pointer !important;
}

.new-app-create-modal-x-button {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-left: 206px;
  margin-bottom: 74px;
  cursor: pointer !important;
}

.analytics-table-head-copy {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  /* padding-left: 24px !important; */
}

.analytics-tables .ant-table-thead>tr>th {
  padding: 0px;
  padding-bottom: 24px;
}

.file-name-head-style {
  color: #8b8b8b !important;
  height: 100px;
  width: 100%;
}

.map-main-container {
  height: 425px;
  width: 100%;
  background-color: #000000;
  margin-bottom: 60px;
}

.map-parent-container {
  margin: 0px 91px;
}

.worldmap__figure-container {
  background: #000000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 425px;
}

.recharts-cartesian-grid-vertical {
  display: none;
}

.profile-screen-input {

  width: 445px !important;


  height: 48px !important;
  color: white !important;
  border: 1px solid #8B8B8B !important;
  padding: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: 'Montserrat';

}

.profile-screen-phone-input {
  margin-left: 84px !important;
  border-radius: 2px;
  width: 361px !important;
  height: 48px !important;
  color: white !important;
  border: 1px solid #8B8B8B !important;
  padding: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: 'Montserrat';

}

.connection-screen-input:focus {
  border: 1px solid #61CEF7 !important;
}

.profile-screen-input:focus,
.profile-screen-phone-input:focus {
  border: 1px solid #61CEF7 !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-popover-arrow-content {
  background-color: #353738 !important;
  z-index: -5 !important;
  height: 50px !important;
  width: 50px !important;
  overflow: visible !important;
}

.ant-popover-arrow {
  overflow: visible !important;
  z-index: -5;
}

.popover-input {
  height: 38px !important;
  width: 212px !important;
  border-color: #8b8b8b !important;
  padding-top: 8px !important;
  color: white !important;
  font-family: 'Montserrat' !important;
}

.popover-input:focus {
  border: 1px solid #61CEF7 !important;
}

.popover-contents {
  padding: 40px 40px 32px 32px;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.view-all-modal-x-button {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-left: 326px;
  margin-bottom: 74px;
  cursor: pointer !important;
}

.recharts-cartesian-axis-tick tspan {
  fill: #EDEDED !important;
  font-family: 'Montserrat';
  font-size: 10px;
}

.step3-phone-input {
  border: 2px solid black;
  height: 55.5px !important;
  width: 445px !important;
}

.lockr-signup-control .react-tel-input .selected-flag {
  padding: 0 0 0 21px !important;
}

.lockr-signup-control .react-tel-input .form-control {
  padding-left: 57px;
}

.profile-screen-phone-input-div .flag-class {
  border: 1px solid #8b8b8b !important;
  border-radius: 2px !important;
  width: 76px;
  padding-left: 18px !important;
}

.licenseAgreement h2,
.licenseAgreement h3 {
  color: white !important
}

.account-error {
  padding-left: 272px;
  color: #f46666;
  margin-bottom: 16px;
}

.connection-list-table>tr>th {
  background-color: black !important;
}

.ant-carousel .slick-dots {
  display: none !important;
}

.connection-hub-search {
  width: 100% !important;
  height: 50px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important; 
  padding-left: 48px !important;
  color: white !important;
  font-family: 'Montserrat' !important;
}

.self-serve-date-picker-input {
  width: 0px;
  height: 0px;
  position: absolute;
  right: 120px;
  z-index: -200;
  top: 80px;
}

.self-serve-date-picker-footer {
  position: absolute;
  right: 15px;
  top: 400px;
  z-index: 120;
  flex-direction: row;
  width: 258px;
  background-color: black;
  display: flex;
  padding-right: 12px;
  padding-left: 12px;
  border-left: 1px solid #8B8B8B;
  border-bottom: 1px solid #8B8B8B;
  border-right: 1px solid #8B8B8B;
  padding-bottom: 12px;
  gap: 60px;
  padding-top: 12px;
}

.rmdp-wrapper {
  position: absolute;
  top: 34px;
}

.rmdp-day.rmdp-disabled{
  color: grey !important;
}

.rmdp-calendar {
  border-top: 1px solid #8B8B8B;
  border-left: 1px solid #8B8B8B;
  border-right: 1px solid #8B8B8B;
  background-color: black;
}

.rmdp-week-day {
  color: #EDEDED !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  visibility: visible !important;
  font-family: "Montserrat", sans-serif !important;
}

.rmdp-header-values {
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  color: white !important;
}

.rmdp-year-picker {
  background-color: black !important;
}

.rmdp-arrow {
  border: solid white !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-shadow {
  box-shadow: none !important;
}

.rmdp-arrow-container {
  font-size: 14px;
}

.rmdp-day {
  color: white !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

.rmdp-range {
  background-color: #61CEF744 !important;
  color:#61CEF7 !important;
  box-shadow: none !important;
}

.ep-arrow {
  visibility: hidden !important;
}

@media only screen and (min-width: 550px) and (max-width: 767px) {
  .logsTable {
    min-width: 60% !important;
  }
  .rowGap{
    gap:0 !important
  }
  .dashboardTile{
    min-width: 40% !important;
  }
  .font-twelve {
    font-size: 5px !important;
  }
  .fontSizeText{
    height: 22px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .logsTable {
    min-width: 60% !important;
  }
  .rowGap{
    gap:0 !important
  }
  .dashboardTile{
    min-width: 40% !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1236px) {
  .logsTable {
    min-width: 60% !important;
  }
  .rowGap{
    gap:0 !important
  }
  .dashboardTile{
    min-width: 40% !important;
  }
}
@media only screen and (min-width: 1237px) and (max-width: 1236px) {
  .logsTable {
    min-width: 70% !important;
  }
  .rowGap{
    gap:0 !important
  }
  .dashboardTile{
    min-width: 30% !important;
  }
}


@media only screen and (min-width: 1280px) and (max-width: 1536px) {
  .logsTable {
    min-width: 70% !important;
  }
  .dashboardTile{
    min-width: 30% !important;
  }
}
@media only screen and (min-width: 1237px) and (max-width: 2560px) {
  .logsTable {
    min-width: 70% !important;
  }
  .dashboardTile{
    min-width: 30% !important;
  }
}